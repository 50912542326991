
.revealerror
{
    animation: revealerror 3s linear 1;
}
@keyframes revealerror
{
    0%
    {
        opacity: 1;
        display: block;
    }
    30%
    {
        opacity: 1;
        display: block;
    }
    60%
    {
        opacity: 0.7;
        display: block;
    }
    90%
    {
        opacity: 0.3;
        display: block;
    }
    100%
    {
        opacity: 0;

    }
}


.reveal
{
    animation: reveal 0.5s linear forwards;
}

@keyframes reveal
{
    from
    {
        opacity: 0;
    }    
    to
    {
        opacity: 1;
    }
}

.form
{
    animation: pop-reveal 1s ease forwards;
}
@keyframes pop-reveal
{
    from
    {
       clip-path: circle(0% at 0 0);
    }
    to
    {
        clip-path: circle(142% at 0 0);
    }  
}


 .logSign #showregister.active::before
 {
    animation: slide-left .4s linear forwards;
 }
@keyframes slide-left
{
    from
    {
        transform: translateX(-100%);
    }
    to
    {
        transform: translateX(0);
    }    
}
.logSign #showlogin.active::before
{
   animation: slide-right .4s linear forwards;
}
@keyframes slide-right
{
   from
   {
       transform: translateX(100%);
   }
   to
   {
       transform: translateX(0);
   }    
}

 .social-signup a:hover i
 {
    animation: ring 0.5s linear forwards;
 }

 @keyframes ring
 {
    0%
    {
        transform: rotate(-15deg);   
    }
    25%
    {
        transform: rotate(15deg);
    }
    50%
    {
        transform: rotate(-15deg);
    } 
    75%
    {
        transform: rotate(15deg);
    }    
 }