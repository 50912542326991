/*L-desktop*/

@media (min-device-width:  1600px)
{

}

/*L-laptops*/
@media(max-width: 1440px)
{

    .wrapper
    {
        padding-left: 60px !important;
    }
	.internalpage  .wrapper
    {
    margin:0 auto !important;   
	padding: 0 !important;
    width: 96% !important;
    height: auto !important;

    }
    .form
    {
        width: 80% !important;
    }
    .side-text
    {
        width: 33% !important;
    }
    

    
}

/*laptops/tablets*/
@media(max-width: 1024px)
{

    .wrapper
    {
        padding-left: 60px !important;
    }
    .form
    {
        width: 80% !important;
    }
    .side-text
    {
        width: 40% !important;
    }
    .form
    {
        padding: 15px !important;
    }
    .form-title
    {
        margin-bottom: 50px !important;
    }
    .input-field
    {
        margin-bottom: 20px !important;
    }
    .login-btn button
    {
        margin-bottom: 20px !important;
    }
    .side-text span
    {
        font-size: 20px !important;
    }
    .main-heading
    {
        font-size: 75px !important;
    }
    .side-text p
    {
        font-size: 15px !important;
    }
    


    




    .thankyou-page article p
    {
        width: 80% !important;
    }
    .social-media
    {
        width: 65% !important;
    }
    .thankyou-page article h1
    {
        font-size: 60px !important;
    }
    .thankyou-page article h1 span
    {
        font-size: 65px !important;
    }
    .thankyou-page article span
    {
        font-size: 18px !important;
    }
    .step-bar-inner
    {
        width: 50% !important;
    }
}


/*tablets*/
@media(max-width: 768px)
{

    .tab-50
    {
        width: 50% !important;
    }
    .tab-100
    {
        width: 100% !important;
    }
    .form
    {
        margin: 0 auto !important;
        /*margin-top: 50px;*/
    }
    .side-text
    {
        position: relative !important;
        width: 80% !important;
        margin: 0 auto !important;
        margin-top: 30px !important;
    }
	.order_1
    {
        order: 1 !important;
    }
    .order_2
    {
        order: 2 !important;
    }
    .wrapper
    {
        width: 100% !important;
        padding-right: 60px !important;
    }
    main::after
    {
        display: none !important;
    }
    .main-inner
    {
        margin-top: 50px !important;
    }
	.internalpage .main-inner {width:100% !important; margin:150px 0 150px !important;}
    .logSign
    {
        width: 100% !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        z-index: 1000000 !important;
        margin-top: 0 !important;
        border-radius: 0 !important;
    }


.subheadlogo { font-size:14px !important; color:#393185 !important; text-align:center !important;}
.poweredby { display:block !important; padding:0 !important; margin:15% 0 0 !important; }
.poweredby p {color:#4b698d !important; font-weight:bold !important; text-align:center !important; border-bottom: 1px solid #fff !important; }
.poweredby ul { display:block !important; padding:0 !important; margin:10px 0 0 !important; }
.poweredby ul li{ display:block; padding:0; margin:0; width:99% }
.maincontentwrap {margin-top:10% !important;}
.form-title {margin-bottom:20px !important;}
.form {min-height:auto !important;}
    .social-media
    {
        width: 85% !important;
    }
}


/*Smart phone*/
@media(max-width: 576px)
{

    .poweredby p {color:#fff !important;  }
    .logo
    {
        margin: 100px 0 30px 0 !important;
    }
    .form
    {
        width: 100% !important;
        padding: 15px !important;
        border-radius: 0 !important;
    }
    .side-text
    {
        position: relative !important;
        width: 100% !important;
        padding: 30px 30px 0 30px !important;
        margin: 0 auto !important;
        margin-top: 30px !important;
    }
    .order_2
    {
        order: 2 !important;
    }
    .wrapper
    {
        width: 100% !important;
        padding: 0 !important;
    }
    main::after
    {
        display: none !important;
    }
    .main-inner
    {
        margin-top: 50px !important;
    }
    .logSign
    {
        width: 100% !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        z-index: 1000000 !important;
        margin-top: 0 !important;
        border-radius: 0 !important;
    }
    .side-text span
    {
        font-size: 14px !important; font-weight:bold;
    }
    .side-text .main-heading
    {
        font-size: 50px !important;
    }
    .side-text p
    {
        font-size: 14px !important;
    }





    

    




    
    .thankyou-page article h1
    {
        font-size: 30px !important;
    }
    .thankyou-page article h1 span
    {
        font-size: 35px !important;
    }
    .thankyou-page article span
    {
        font-size: 15px !important;
    }
    .thankyou-page article p
    {
        font-size: 15px !important;
        width: 90% !important;
    }
    .thankyou-page .logo-text
    {
        font-size: 60px !important;
    }
    .social-media
    {
        width: 100% !important;
    }


    
}

@media(max-width: 576px)
{
    .poweredby p {color:#fff !important;  }
    .signUpDiv{ width: auto;}
	.col-12{ margin-bottom:20 !important;}
    .options{
        font-size: 12px !important;
    }
    .internalpage  .wrapper {
        margin:0 !important;   
        padding: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
}